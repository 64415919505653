import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../components/Seo';
import ToolRender from '../components/ToolRender';
import FooterCredit from '../components/FooterCredit';

type PageContextType = {
	slug: string;
};
export default function DocTemplate(
	props: PageProps<GatsbyTypes.ToolPageDataQuery, PageContextType>
) {
	const {
		data: { toolPage },
	} = props;

	return (
		<>
			<Seo
				title={toolPage?.frontmatter?.title}
				description={toolPage?.frontmatter?.description}
			/>
			<ToolRender data={toolPage} slug={props.pageContext.slug} />
			<FooterCredit />
		</>
	);
}

export const query = graphql`
	query ToolPageData($slug: String!) {
		toolPage: mdx(fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				description
				menuName
			}
			tableOfContents
			body
		}
	}
`;
