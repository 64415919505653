import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import styled from 'styled-components';
import {
	DocSidebarContent,
	DocSidebarMainGrid,
	SidebarContainer,
	ToggleButton,
} from '../DocsSidebar';
import { Body, TocContainer, DocArticle, DocTitle } from '../Doc/styled';
import MDXProvider from '../Typography/MDXProvider';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Constrain } from '../Containers';
import { graphql, useStaticQuery } from 'gatsby';
import DocTypeNav from '../DocsSidebar/DocTypeNav';
import Card from '../Card';
import { ns } from '../../utils';
import Toc from '../Doc/Toc';

const CardsContainer = styled.div`
	margin: ${props => ns(props.theme.gutter * 2)} 0;
`;

export type ToolRenderProps = {
	data: GatsbyTypes.ToolPageDataQuery['toolPage'];
	slug: string;
};
export default function ToolRender(props: ToolRenderProps) {
	// sidebar visibility
	const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
	const sidebarRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const handler = (event: MouseEvent) => {
				if (
					(event.target as HTMLElement).isSameNode(sidebarRef.current) ||
					sidebarRef.current?.contains(event.target as HTMLElement)
				) {
					return;
				}
				setSidebarVisible(false);
			};
			window.document.addEventListener('click', handler);
			return () => {
				window.document.removeEventListener('click', handler);
			};
		}
		return () => {};
	}, [setSidebarVisible]);

	const data = useStaticQuery<GatsbyTypes.ToolsSidebarQueryQuery>(graphql`
		query ToolsSidebarQuery {
			allMdx(
				filter: {
					fileAbsolutePath: { glob: "**/site-data/tools/**/*.mdx" }
					fields: { slug: { ne: "/tools/" } }
				}
				sort: { fields: frontmatter___order, order: ASC }
			) {
				edges {
					node {
						fields {
							slug
						}
						frontmatter {
							title
							menuName
							description
						}
					}
				}
			}
		}
	`);

	return (
		<Constrain>
			<DocSidebarMainGrid>
				<SidebarContainer
					className={`sidebar sidebar--${
						sidebarVisible ? 'visible' : 'hidden'
					}`}
					ref={sidebarRef}
				>
					{data.allMdx.edges.map(({ node }) => (
						<DocTypeNav
							key={node.fields?.slug}
							docType={node.fields!.slug!}
							isDocTypeGeneral
							label={node.frontmatter?.menuName ?? node.fields!.slug!}
							queryData={{ edges: [] }}
						/>
					))}
				</SidebarContainer>
				<ToggleButton
					aria-label="Toggle documentation sidebar visibility"
					className={`hamburger ${
						sidebarVisible ? 'is-active' : 'is-inactive'
					}`}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						setSidebarVisible(v => {
							const newVisibility = !v;
							if (newVisibility) {
								sidebarRef.current?.focus();
							}
							return newVisibility;
						});
					}}
				>
					<FontAwesomeIcon icon={sidebarVisible ? faArrowLeft : faArrowRight} />
				</ToggleButton>
				<DocSidebarContent>
					<DocTitle>{props.data?.frontmatter?.title}</DocTitle>
					{props.slug !== '/tools/' ? (
						<Body>
							<TocContainer>
								<Toc
									items={
										(props.data?.tableOfContents as any)?.items ?? undefined
									}
								/>
							</TocContainer>
							<DocArticle>
								<MDXProvider>
									<MDXRenderer>{props.data!.body}</MDXRenderer>
								</MDXProvider>
							</DocArticle>
						</Body>
					) : (
						<MDXProvider>
							<MDXRenderer>{props.data!.body}</MDXRenderer>
						</MDXProvider>
					)}
					{props.slug === '/tools/' ? (
						<CardsContainer>
							<Card.Grid>
								{data.allMdx.edges.map(({ node }) => (
									<Card to={node.fields!.slug}>
										<Card.Title>{node.frontmatter?.title}</Card.Title>
										<Card.Description>
											{node.frontmatter?.description}
										</Card.Description>
										<Card.FakeButton>
											{node.frontmatter?.menuName}
										</Card.FakeButton>
									</Card>
								))}
							</Card.Grid>
						</CardsContainer>
					) : null}
				</DocSidebarContent>
			</DocSidebarMainGrid>
		</Constrain>
	);
}
