import React from 'react';
import styled from 'styled-components';
import { bp, gv, ns } from '../../utils';
import BgShape from '../BgShape';
import { Constrain, GeneralBody } from '../Containers';
import Cta from '../Cta';
import { Highlight } from '../Typography';

const Heading = styled.h4`
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.large4)};
	margin: 0 0 ${props => ns(props.theme.gutter * 2)} 0;
	color: inherit;
	font-weight: bold;
`;

const Text = styled.p`
	font-size: ${props => ns(props.theme.fz.large2)};
	margin: 0;
	color: inherit;
	line-height: 1.5;
`;

const Article = styled.article``;
const Control = styled.aside``;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-flow: row wrap;
	${Article},
	${Control} {
		flex: 0 0 100%;
	}
	@media ${props => bp(props, 'tablet')} {
		${Control} {
			flex-basis: 250px;
		}
		${Article} {
			flex-basis: calc(100% - 250px);
			max-width: 750px;
			padding-right: ${props => ns(props.theme.gutter)};
		}
	}
`;

const Container = styled.section`
	background-color: ${gv('primaryColor')};
	color: ${gv('primaryBgText')};
	margin: 0;
	${GeneralBody} {
		padding-top: ${props => ns(props.theme.gutter * 3.5)};
		padding-bottom: ${props => ns(props.theme.gutter * 3.5)};
		margin-top: 0;
		margin-bottom: 0;
	}
`;

export default function FooterCredit() {
	return (
		<Container>
			<BgShape mode="primary">
				<Constrain>
					<GeneralBody>
						<Wrapper>
							<Article>
								<Heading>Start building beautiful forms!</Heading>
								<Text>
									Take the next step and get started with WPEForm today. You
									have the option to start with the free version, or get started
									with a trial. All your purchases are covered under{' '}
									<Highlight>30 days</Highlight>{' '}
									<Highlight>Money Back Guarantee</Highlight>.
								</Text>
							</Article>
							<Control>
								<Cta.Group size="large" align="center">
									<Cta type="inverted" to="/pricing/" size="large">
										Get Started
									</Cta>
								</Cta.Group>
							</Control>
						</Wrapper>
					</GeneralBody>
				</Constrain>
			</BgShape>
		</Container>
	);
}
